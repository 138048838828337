<template>
  <div class="body">
    <div class="content">
      <div class="title">
        <span>培训权限</span>
      </div>
      <div style="margin-top: 50px"></div>
      <div class="list">
        <el-form size="mini">
          <el-form-item label="视频统一普通访问密码（视频独立密码，需在当前视频设置）">
            <el-input v-model="password" maxlength="15" style="width: 299px" size="medium" placeholder="设置视频访问密码"></el-input>
          </el-form-item>
          <el-button type="primary" @click="submit" size="medium">设置统一视频密码</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/cloud'
export default {
  data() {
    return {
      password: '',
      project: localStorage.getItem('mall-project')
    }
  },
  created() {
    this.getPassword()
  },
  methods: {
    getPassword() {
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'getSettings',
          project: JSON.parse(this.project)._id
        })
        .then(res => {
          console.log(res)
          if (res.trainPassword) {
            this.password = res.trainPassword
          }
        })
    },
    submit() {
      if (this.password == '') {
        this.$message.error('请输入密码')
        return
      }
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'updateSettings',
          trainPassword: this.password,
          project: JSON.parse(this.project)._id
        })
        .then(res => {
          this.$message({
            message: '设置成功',
            type: 'success'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  height: 100%;
  padding: 15px;

  .content {
    height: 100%;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    .list {
      display: flex;
      align-items: center;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      padding-left: 12px;
      border-left: 6px solid #6775e1;
    }
  }
}
</style>
